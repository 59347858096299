export interface IPageData {
  type?: string
  title?: string
  body: string
  originalBody: string
}

export interface IWhoToCallData {
  operations: string
  customerRelations: string
  humanResources: string
  sales: string
  marketing: string
  ceo: string
  procurement: string
  inventory: string
  accountsPayable: string
  accountsReceivable: string
  payroll: string
}
