import { IUrlFilters } from '@lla-platform/core/core-data-access'

export interface IWorkOrdersSummaryRequest {
  locationIds?: string[]
}

export interface IWorkOrdersSummaryItem extends IUrlFilters {
  locationId?: string
  locationName?: string
  interval30_60: number
  interval60_90: number
  intervalGreater90: number
  grandTotals: number
}

export interface IWorkOrdersSummaryResponse {
  workOrders: IWorkOrdersSummaryItem[]
  interval30_60: number
  interval60_90: number
  intervalGreater90: number
  grandTotals: number
}

export interface IWorkOrdersExtendedResponse {
  workOrders: IWorkOrdersSummaryItem[]
  grandTotal: IWorkOrdersSummaryItem
}
